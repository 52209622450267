import i18n from '@/i18n';

const suggestions = {
    keyword_term: {
        label: 'Keyword Term',
        type: 'text',
        value: ''
    },
    position: {
        label: 'Average Position',
        tooltip: i18n.t('kw-int.avg-pos-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
    impressions: {
        label: 'Impressions',
        tooltip: i18n.t('kw-int.impressions-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
    word_count: {
        label: 'Word Count',
        tooltip: i18n.t('kw-int.word-count-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
    clicks: {
        label: 'Clicks',
        tooltip: i18n.t('kw-int.clicks-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
    ctr: {
        label: 'CTR',
        tooltip: i18n.t('kw-int.ctr-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
}

const related = {
    keyword_term: {
        label: 'Keyword Term',
        type: 'text',
        value: '',
    },
    word_count: {
        label: 'Word Count',
        tooltip: i18n.t('kw-int.word-count-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
    search_volume: {
        label: 'Search Volume',
        tooltip: i18n.t('kw-int.ms-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
    competition: {
        label: 'Competition',
        tooltip: i18n.t('kw-int.competition-tooltip'),
        type: 'dropdown',
        placeholder: 'Select competition level',
        options: [
            {value: 'Low', label: 'Low'},
            {value: 'Medium', label: 'Medium'},
            {value: 'High', label: 'High'},
        ],
        value: '',
    },
    cpc: {
        label: 'Cost Per Click (CPC)',
        tooltip: i18n.t('kw-int.cpc-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
}

export const suggestionsAutoAddFilters = {
    position: {
        label: 'Average Position',
        tooltip: i18n.t('kw-int.avg-pos-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
    impressions: {
        label: 'Impressions',
        tooltip: i18n.t('kw-int.impressions-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
    ctr: {
        label: 'CTR',
        tooltip: i18n.t('kw-int.ctr-tooltip'),
        type: 'numeric',
        filter: '=',
        value: '',
        max: ''
    },
}

export const filterBy = [
    { label: i18n.t('eq'), value: '=' },
    { label: i18n.t('gt'), value: '>' },
    { label: i18n.t('lt'), value: '<' },
    { label: i18n.t('bw'), value: 'between' },
]

export const filterFields = {
    suggestions,
    related: related,
    competitors: related
}
