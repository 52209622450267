<template>
    <div
        class="keyword-suggestions-table"
        :class="{ empty: !list.length }"
    >
        <div class="header">
            <div
                class="header__cell header__cell_checkbox"
                :style="getColumnStyle({ width: 32 })"
                :data-important-tooltip="$t('select-all')"
                @click="selectAll"
            >
                <div class="header__cell-inner">
                    <checkbox
                        :icon-class="getCheckBoxIconClass"
                        :checkbox="partialSelected"
                        @checkbox-click="selectAll"
                    />
                </div>
            </div>
            <div
                v-for="(field, i) in relatedKwsFields"
                :key="i"
                :class="headerCellClass(field)"
                @click="sorterChanged(field.sorter)"
            >
                <div class="header__cell-inner" v-text="field.title" />
                <span
                    v-if="field.tooltip"
                    class="kw kw-info"
                    @mouseover="tooltip({ text: field.tooltip, event: $event })"
                />
                <span class="kw kw-sort" />
            </div>
        </div>
        <div v-if="!loading" :class="bodyClasses">
            <div
                v-for="(suggestion, i) in list"
                :key="i"
                class="body__row"
            >
                <div
                    class="body__cell"
                    :style="getColumnStyle({ width: 32 })"
                >
                    <checkbox
                        :checkbox="isSelected(suggestion['keyword'])"
                        @checkbox-click="select(suggestion['keyword'])"
                    />
                </div>
                <div
                    v-for="(field, j) in relatedKwsFields"
                    :key="j"
                    :class="bodyCellClass(field)"
                >
                    <template v-if="field.name === 'keyword'">
                        <div
                            @click="$emit('addOne', suggestion['keyword'])"
                            class="action-btn add-kw-btn"
                            @mouseover="tooltip({ text: $t('add-keyword-to-project-or-group'), event: $event })"
                        >
                            <span class="kw kw-plus" />
                        </div>
                        <span
                            class="body__cell-inner"
                            v-html="getValue(suggestion, field.name)"
                            :title="getValue(suggestion, field.name)"
                        />
                    </template>
                    <span
                        v-else
                        class="body__cell-inner"
                        v-html="getValue(suggestion, field.name)"
                    />
                </div>
            </div>
        </div>
        <div v-else :class="`placeholder placeholder_animate`">
            <div class="placeholder__item placeholder__item_suggestions">
                <div
                    v-for="i in 7"
                    :key="i"
                    :class="`placeholder__suggestions placeholder__suggestions-keywords`"
                >
                    <div v-for="j in 11" :key="j" />
                </div>
            </div>
        </div>
        <table-pagination
            v-if="totalPages > 1"
            :current-page="currentPage"
            :per-page="100"
            :total-records="totalCount"
            :total-pages="totalPages"
            :on-change-page="pageChanged"
        />
    </div>
</template>

<script>
import { relatedKwsFields } from './fields';
import KeywordsTableMixin from '@/mixins/KeywordsTableMixin';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
export default {
    name: "RelatedKeywordsTable",
    mixins: [KeywordsTableMixin, ToolTipRenderMixin],
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        list: {
            type: Array,
            required: true,
        },
        preventClick: {
            type: Boolean,
            required: false,
            default: false
        },
        selected: {
            type: Array,
            default: () => []
        },
        sortField: {
            type: Object,
            default: () => { }
        },
        currentPage: {
            type: Number,
            default: 1
        },
        totalPages: {
            type: Number,
            default: 1
        },
        totalCount: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            relatedKwsFields,
        };
    },
    computed: {
        bodyClasses() {
            return {
                body: true,
                body_empty: !this.loading && !this.list.length,
            };
        },
        partialSelected() {
            return !this.preventClick && !!this.selected.length;
        },
        getCheckBoxIconClass() {
            return this.allSelected ? 'kw kw-check' : 'kw kw-minus';
        },
        allSelected() {
            return this.list.length === this.selected.length;
        },

    },
    methods: {
        selectAll() {
            this.$emit('selectAll', this.allSelected);
        },
        headerCellClass(f) {
            const { field, direction } = this.sortField;

            return {
                header__cell: true,
                [`header__cell_${f.class}`]: true,
                [direction]: f.sorter === field,
            };
        },
        sorterChanged(sorter) {
            this.$emit('sortChanged', sorter);
        },
        select(kw) {
            this.$emit('onSelect', kw, this.isSelected(kw));
        },
        isSelected(keyword) {
            return this.selected.includes(keyword);
        },
        bodyCellClass(f) {
            return {
                body__cell: true,
                [`body__cell_${f.class}`]: true,
            };
        },
        getValue(suggestion, field) {
            let empty = '<span style="color: #C1C4CD;">-</span>';

            switch (field) {
                case 'competition':
                    return `<span class="competition-tag tag-${suggestion[field]}">${suggestion[field]}</span>`;
                case 'cpc':
                    return suggestion[field] ? `$<span style="color: #000000;">${suggestion[field]}</span>` : empty;
                default:
                    return suggestion[field] ?? empty;
            }
        },
        pageChanged(page) {
            this.$emit('pageChanged', page);
        }
    }
}
</script>

<style lang="scss" scoped>
.keyword-suggestions-table {
    margin-top: 8px;
    border: 1px solid #e1e8ef;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .header {
        align-items: center;
        border-radius: 4px 4px 0 0;
        color: kw-color(kw-grey, 1);
        cursor: default;
        display: flex;
        flex-flow: row nowrap;
        font-size: 12px;
        font-weight: 500;
        line-height: 34px;
        text-transform: capitalize;
        user-select: none;
    }

    .header__cell {
        @import '~sass/cutting-name';
        align-items: center;
        background-color: kw-color(kw-white, 4);
        border-bottom: 1px solid #e1e8ef;
        border-right: 1px solid #e1e8ef;
        cursor: pointer;
        display: flex;
        height: 34px;
        min-width: 74px;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        white-space: nowrap;
        flex: 1;
        justify-content: center;
        height: 36px;

        .header__cell-inner {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.06px;
        }

        &:last-child {
            border-right: none;
        }

        .kw-sort {
            display: none;
            font-size: 8px;
            margin-left: 0;
            transform: scaleX(0.75);
        }

        &.asc {
            .kw-sort {
                display: block;
                transform: scaleY(-1) scaleX(0.75);
            }
        }

        &.desc {
            .kw-sort {
                display: block;
            }
        }

        /deep/ .checkbox {
            top: 8px;
            left: 8px;
        }
    }

    .header__cell_project {
        min-width: 200px;
    }

    .header__cell_recipient {
        min-width: 120px;
    }

    .header__cell-inner {
        @import '~sass/cutting-name';
    }

    .body__row {
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;

        &:nth-child(odd) {
            .body__cell {
                background-color: kw-color(kw-white, 1);
            }
        }

        &:nth-child(even) {
            .body__cell {
                background-color: #f7f8fa;
            }
        }

        &:not(:hover) {
            /deep/ a {
                color: #828f9c;
            }
        }

        &:hover {
            .body__cell-actions {
                display: flex;
            }
        }
    }

    .body__cell {
        align-items: center;
        border-right: 1px solid #e1e8ef;
        color: #333;
        display: flex;
        flex-flow: row nowrap;
        font-size: 14px;
        height: 36px;
        line-height: 34px;
        min-width: 74px;
        padding: 0 12px;
        position: relative;
        flex: 1;
        justify-content: flex-end;

        &:last-child {
            border-right: none;
        }

        /deep/ a {
            color: kw-color(kw-blue, 1);
        }

        /deep/ .checkbox {
            top: 8px;
            left: 8px;
        }
    }

    .body__cell_recipient {
        min-width: 120px;
    }

    .body__cell_project {
        justify-content: space-between;
        min-width: 200px;
    }

    .body__cell_format {
        text-transform: uppercase;
    }

    .body__cell-actions {
        display: none;
        flex-flow: row nowrap;

        span {
            color: kw-color(kw-blue, 1);
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }

    .body__cell-inner {
        @import '~sass/cutting-name';
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        text-align: right;
        color: kw-color(kw-grey, 1);
    }

    .header__cell_gsc-kw,
    .body__cell_gsc-kw {
        justify-content: flex-start;
        min-width: 274px;
    }

    .body__cell_gsc-kw {
        .body__cell-inner {
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            flex: 1;
        }
    }

    .kw-info {
        font-size: 10px;
        font-weight: 900;
        color: kw-color(kw-light-grey, 5);
        margin: 0 4px;
    }

    .action-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;

        &:hover {
            background-color: #f2f3f4;
        }
    }

    .add-kw-btn {
        width: 24px;
        height: 24px;
        color: kw-color(kw-blue, 1);
        margin-right: 8px;

        span {
            font-size: 14px;
            font-weight: 900;
        }
    }

    .header__cell_gsc-kw,
    .body__cell_gsc-kw {
        width: 100%;
    }

    .header__cell_gsc-ms,
    .body__cell_gsc-ms,
    .header__cell_gsc-competition,
    .body__cell_gsc-competition,
    .header__cell_gsc-cpc,
    .body__cell_gsc-cpc {
        max-width: 200px;
    }

    .body__cell_gsc-competition {
        justify-content: flex-start;
    }
}
</style>