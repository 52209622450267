export default (i18n, toastr) => {
	return error => {
		if (!error) return Promise.reject(i18n.t('unexpected-error'));

		const { config, response } = error;

		if (!config || !response) {
			return Promise.reject(error);
		}

		const { errors } = response.data;

		if ((config.method === 'post' && (config.url.includes('/connections') || config.url.includes('/filter')))
			|| config.url.includes('/ideas')) {
			errors?.forEach(element => {
				if (element.message) {
					toastr.e(element.message);
				}
				if (element.detail) {
					toastr.e(element.detail);
				}
			});
		}

		return Promise.reject(error);
	};
};
