<template>
    <div class="suggestions-main">
        <placeholder-cell
            v-if="loading || getGSCUsernameIsFetching || getGSCProfileConnectionsIsFetching || getGSCProfilesIsFetching"
            :width="200" :height="14" />
        <div v-else-if="getGSCProfileCurrentConnection">
            <div class="settings-row"><b>{{ $t('connected-gsc-profile') }}:</b> <span>{{ getConnectionProfile }}</span>
                <span @click="openSettings" class="kw kw-settings icon" /></div>
            <div class="filter-main">
                <current-filters
                    @onSave="openSaveFilters"
                    @onReset="onFiltersReset"
                    v-if="getGSCFilters"
                >
                    <filter-item
                        v-for="item in currentFilters"
                        :key="item.label"
                        :label="item.label"
                        :filterType="item.filterType"
                        :value="item.value"
                        @onDelete="onDeleteFilter(item.key)"
                    />
                </current-filters>
                <div class="filter-row">
                    <div class="filter-gsc__wrapper dropdown_container" data-cy="filter-gsc-wrap">
                        <kw-button button-type="secondary" prepend-icon-class="kw kw-filter"
                            data-cy="dashboard-filter-button" @click="toggleFilters" />
                            
                        <custom-dropdown-body ref="filtersDropdown" adjustY>
                            <div class="v-context" data-cy="dashboard-filter-menu">
                                <keyword-suggestions-filters
                                    key="suggestions"
                                    tab="suggestions"
                                    @onSave="openSaveFilters"
                                    @close="toggleFilters"
                                    @onApply="applySuggestionsFilters"
                                />
                            </div>
                        </custom-dropdown-body>
                    </div>
                </div>
            </div>
            <keyword-suggestions-table :loading="getGSCSuggestionsIsFetching" :suggestions="getGSCSuggestions" />
        </div>
        <div v-else-if="getGSCUsername">
            <connect-gsc-account @onConnect="openSettings" />
        </div>
        <div v-else>
            <connect-gsc-account @onConnect="locateTo(googleAuthLink)" />
            <!-- <suggestions-placeholder @onConnectAccount="locateTo(googleAuthLink)" /> -->
        </div>
    </div>
</template>

<script>
import { GTM_EVENTS } from '@/constants';
import { Events, EventBus } from '@/events';
import { mapGetters, mapActions } from 'vuex';
import { SET_CURRENT_GROUP_OBJECT } from '@/store/mutations';
import KeywordSuggestionsTable from './KeywordSuggestionsTable.vue'
import KeywordSuggestionsSettingsModal from '../modals/KeywordSuggestionsSettingsModal.vue';
import SuggestionsPlaceholder from './SuggestionsPlaceholder.vue';
import SocialSignInMixin from '@/mixins/SocialSignInMixin';
import ConnectGSCAccount from './ConnectGSCAccount.vue';
import KeywordSuggestionsFilters from './KeywordSuggestionsFilters.vue'
import CurrentFilters from './CurrentFilters.vue';
import FilterItem from './FilterItem.vue';
import SaveFilterModal from '@/components/modals/SaveFilterModal.vue';
import { beforeModalOpen, beforeModalClose } from '@/helpers/modalHelpers';

export default {
    name: "Suggestions",
    components: {
        'keyword-suggestions-table': KeywordSuggestionsTable,
        'suggestions-placeholder': SuggestionsPlaceholder,
        'connect-gsc-account': ConnectGSCAccount,
        'keyword-suggestions-filters': KeywordSuggestionsFilters,
        'current-filters': CurrentFilters,
        'filter-item': FilterItem
    },
    mixins: [SocialSignInMixin],
    data() {
        return {
            loading: true
        }
    },
    computed: {
        ...mapGetters([
            'getUserData',
            'getGSCUsername',
            'getPermissionData',
            'getGSCSuggestions',
            'getCurrentProject',
            'getGSCProfilesIsFetching',
            'getGSCUsernameIsFetching',
            'getGSCSuggestionsIsFetching',
            'getGSCProfileCurrentConnection',
            'getGSCProfileConnectionsIsFetching',
            'getGSCFilters'
        ]),
        checkboxOptions() {
            return {
                showCheckbox: true,
                isHeaderCheckboxActive: false,
                isAllChecked: false,
            };
        },
        getConnectionProfile() {
            return this.getGSCProfileCurrentConnection
                ? this.getGSCProfileCurrentConnection.originalDomain
                : null;
        },
        currentFilters() {
            const result = [];
            if (this.getGSCFilters) {
                Object.entries(this.getGSCFilters).forEach(([key, fValue]) => {
                    let currentValue = fValue.value;

                    if (fValue.filter === 'between') {
                        currentValue = [fValue.value, fValue.max]
                    }
                    result.push({
                        key,
                        label: this.$t(`kw-int.filters.${key}`),
                        filterType: fValue.filter,
                        value: currentValue
                    });
                });
            }
            return result;
        },
    },
    created() {
        if (!this.getPermissionData('google_integrations')) {
            this.$router.push({ name: 'googleSearchConsole' });
            return;
        }

        if (this.getCurrentProject) {
            if (!this.getGSCSuggestions?.length) {
                this.fetchData();
            } else {
                this.loading = false;
            }
        } else {
            this.setCurrentProject();
        }

        this.unSubscribe = this.$store.subscribe(({ type }) => {
            if (type === SET_CURRENT_GROUP_OBJECT) {
                this.fetchData();
            }
        });
    },
    mounted() {
        EventBus.emit(Events.OPEN_SUGGESTIONS_TAB);
        /**
         * GTM custom event suggestions_tab_opened
         */
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: GTM_EVENTS.CLICK_SUGGESTIONS_TAB,
            uid: this.getUserData.userId,
        });
    },
    methods: {
        ...mapActions([
            'fetchGSCAccount',
            'fetchGSCProfiles',
            'setCurrentProject',
            'fetchGSCProfileConnection',
            'fetchGSCDeletedSuggestions',
            'deleteGSCFilterItem',
            'setCurrentSavedFilter',
            'setGSCFilters',
            'fetchGSCSuggestions'
        ]),
        async fetchData() {
            try {
                await this.fetchGSCAccount();
                await this.fetchGSCProfiles();
                await this.fetchGSCProfileConnection();
                await this.fetchGSCDeletedSuggestions();
                this.loading = false;
            } catch (error) {
                this.$toastr.e(error);
            }
        },
        fieldFormatter(project, header) {
            if (header.name === 'id') {
                return project.id.replace(
                    '[sub]',
                    '<span class="kw kw-corner-down-right"></span>',
                );
            }
        },
        openSettings() {
            this.$modal.show(
                KeywordSuggestionsSettingsModal,
                {},
                {
                    classes: 'v--modal-full-height',
                    height: 'auto',
                    name: 'KeywordSuggestionsSettingsModal',
                    width: 514,
                },
                {
                    'before-open': beforeModalOpen,
                    'before-close': beforeModalClose
                }
            );
        },
        onDeleteFilter(filterKey) {
            this.deleteGSCFilterItem(filterKey);
        },
        toggleFilters() {
            this.$refs.filtersDropdown.toggleDropdownOpen();
        },
        closeFiltersDropdown() {
            if (this.$refs.filtersDropdown?.getIsDropdownOpened) {
                this.$refs.filtersDropdown.closeDropdown();
            }
        },
        openSaveFilters(filters) {
            if (!this.getCurrentProject) return;
            const currentFilters = filters || this.getGSCFilters;

            this.$modal.show(
                SaveFilterModal,
                {
                    project: this.getCurrentProject,
                    filters: currentFilters,
                    tabName: 'suggestions'
                },
                {
                    width: 408,
                    height: 'auto',
                    classes: 'v--modal center-modal-popup',
                },
                {
                    'before-close': this.closeFiltersDropdown,
                },
            );
        },
        onFiltersReset() {
            this.setCurrentSavedFilter({ key: 'suggestions', value: null });
            this.setGSCFilters();
            this.fetchGSCSuggestions();
        },
        applySuggestionsFilters() {
            this.fetchGSCSuggestions();
        }
    },
    beforeDestroy() {
        if (this.unSubscribe) {
            this.unSubscribe();
        }
    },
}
</script>

<style lang="scss" scoped>
.suggestions-main {
    width: 100%;
    min-height: 700px;
}

.settings-row {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 10px 0 10px;
    gap: 4px;

    b {
        font-weight: 500;
    }

    .icon {
        margin-left: 4px;
        font-weight: 700;
        color: kw-color(kw-blue, 1);
        cursor: pointer;
    }
}
</style>