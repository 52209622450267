export const getPercentage = (count, total) => {
    return !total ? '0%' : `${(count * 100 / total).toFixed(2)}%`
};

export const detectIsViewKey = () => {
    const path = window.location.pathname;
   
    const shareRegex = /^\/(project-share|tag-share|share-view)/;

    if(shareRegex.test(path)){
        return true;
    }

    const regex = /^\/projects\/([^\/]+)\/([^\/]+)(?:\/([^\/]+))?$/;
    const match = path.match(regex);

    if (match && match.length >= 3 && match[3] !== 'add') {
        return true;
    }

    return null;
};

export const detectIsSurferSEO = () => {
    return window.location.host === "ranktracker.surferseo.app" || window.location.host === "ranktracker.surferseo.com" || window.location.search.includes('whitelabel=surferseo');
}

export const isEmptyObject = (value) => {
    if(!value) return true;
    if(typeof value === 'object' && !Array.isArray(value)){
        return !Object.values(value).some(item => item !== null);
    } else {
        return true;
    }
}

export const matchesFilter = (filter, value, filterValue = null, maxValue = null) => {
    if (!filter) return true;
    switch (filter) {
        case '=': return value == filterValue;
        case '>': return value > filterValue;
        case '<': return value < filterValue;
        case 'between': return value > filterValue && value < maxValue;
        default: return false;
    }
}

export const paginateData = (items, currentPage, pageSize) => {
    const totalItems = items.length;
    const totalPages = Math.ceil(totalItems / pageSize);

    const safeCurrentPage = Math.min(Math.max(currentPage, 1), totalPages);

    const startIndex = (safeCurrentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const currentItems = items.slice(startIndex, endIndex);

    return {
        currentPage: safeCurrentPage,
        totalPages,
        pageSize,
        totalItems,
        currentItems
    };
}

export const parseGSCFilters = (filters) => {
    const parsedFilters = {};
    if (!filters) return parsedFilters;
    Object.entries(filters).forEach(([key, value]) => {
        if (value.filter) {
            if (value.value !== '') {
                parsedFilters[`${key}_symbol`] = value.filter;
                parsedFilters[`${key}_min`] = +value.value;
            }

            if (value.filter === 'between') {
                parsedFilters[`${key}_max`] = +value.max;
            }
        } else if (value.value) {
            parsedFilters[key] = value.value;
        }
    });

    return parsedFilters;
}

export const capitalize = (val) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}
