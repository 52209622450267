<template>
    <div class="kw-search-wrap">
        <div class="inner search">
            <span class="kw kw-search" />
            <kw-input-field name="search" v-model.trim="search" :placeholder="placeholder" :error-data="error.search" />
            <span v-if="search" @click="clearSearch" class="custom-x" />
        </div>
        <div class="inner location">
            <span class="kw kw-map-pin" />
            <custom-google-autocomplete
                id="search-google-autocomplete"
                ref="locationGMB"
                :class="inputClasses"
                :data-cy="`add-keyword-${keywordType}-listings`"
                placeholder="Enter Location"
                :types="'(cities)'"
                @inputChange="handleGmbChange"
                @onPlaceSelected="onPlaceSelected"
            />
            <span v-if="location" @click="clearLocation" class="custom-x" />
        </div>
        <div class="inner lang">
            <span class="kw kw-globe" />
            <custom-v-select
                id="lang"
                options-label="label"
                options-value="code"
                select-type="language"
                search-key="label"
                data-cy="lang-dropdown"
                placeholder="Enter Language"
                search
                :options="languagesList"
                :value.sync="lang"
                dropdown-menu-data-cy="lang-list"
                dropdown-search-data-cy="lang-search"
                @change="onLangChange($event)"
            />
        </div>
        <div class="submit">
            <kw-button 
                :button-text="$t('find-keywords')"
                button-type="primary"
                data-cy="find-keywords"
                @click="onSubmit"
                :loading="loading"
            />
        </div>
    </div>
</template>

<script>
import GoogleApiMixin from '@/mixins/GoogleApiMixin';
import CustomGoogleAutocomplete from '@/components/ui-elements/CustomGoogleAutocomplete';
import { GOOGLE_ADS_LANGUAGE_IDS } from '@/constants';

export default {
    name: "SearchWrap",
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Enter Keyword'
        },
        error: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        'custom-google-autocomplete': CustomGoogleAutocomplete,
    },
    mixins: [
        GoogleApiMixin,
    ],
    computed: {
        inputClasses() {
            return {
                'text-input': true,
                'text-input_invalid': this.invalid,
            };
        },
    },
    data() {
        return {
            keywordType: 'web',
            search: '',
            location: '',
            fullLocation: {
                country: '',
                region: '',
                city: '',
            },
            lang: '',
            languagesList: GOOGLE_ADS_LANGUAGE_IDS,
            locationKeys: {
                country: 'country',
                administrative_area_level_1: 'region',
                locality: 'city'
            }
        }
    },
    methods: {
        onSubmit() {
            this.$emit('submit', {
                search: this.search,
                lang: this.lang,
                country: this.fullLocation.country,
                region: this.fullLocation.region,
                city: this.fullLocation.city,
            })
        },
        onLangChange(val) {
            this.lang = val;
        },
        handleGmbChange(v) {
            this.location = v.newVal;
        },
        clearSearch() {
            this.search = '';
        },
        clearLocation() {
            this.location = '';
            let el = document.getElementById('search-google-autocomplete');
            el.value = '';
        },
        onPlaceSelected(value) {
            if (value?.address_components) {
                value.address_components.forEach(element => {
                    if (element.types[0] in this.locationKeys) {
                        this.fullLocation[this.locationKeys[element.types[0]]] = element.long_name;
                    }
                });
            } else {
                this.fullLocation = {
                    country: '',
                    region: '',
                    city: '',
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.kw-search-wrap {
    width: 100%;
    max-width: 1100px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 12px 0;

    .custom-x {
        background-color: #667085;
        cursor: pointer;
    }

    .submit {
        button {
            height: 40px;
            min-width: 120px;
        }
    }

    .inner {
        flex: 1;
        position: relative;

        &.search {
            max-width: 454px;

            .kw__inputfield {
                width: 100%;

                /deep/ .kw__input {
                    border-color: #CBD6E9;
                    height: 38px;
                    padding-left: 32px;
                    font-size: 13px;
                    padding-right: 20px;

                    &::placeholder {
                        color: #8B91A2;
                    }
                }
            }

            .kw-search {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                color: #667085;
                font-weight: 700;
            }

            .custom-x {
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                right: 10px;
            }
        }

        &.location {
            max-width: 240px;

            /deep/ .text-input {
                width: 100%;
                padding-left: 24px;
                font-size: 13px;
                padding-right: 20px;
                border-color: #CBD6E9;
                height: 38px;
            }

            .kw-map-pin {
                position: absolute;
                left: 6px;
                top: 50%;
                transform: translateY(-50%);
                color: #63697B;
                font-weight: 700;
                font-size: 13px;
            }

            .custom-x {
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                right: 10px;
            }
        }

        &.lang {
            max-width: 240px;

            .kw-globe {
                position: absolute;
                left: 6px;
                top: 50%;
                transform: translateY(-50%);
                color: #63697B;
                font-weight: 700;
                font-size: 13px;
                z-index: 2;
            }

            .btn-group {
                width: 100%;

                /deep/ .dropdown-toggle {
                    padding-left: 24px;
                    height: 38px;
                    border-color: #CBD6E9 !important;

                    .is-placeholder {
                        font-weight: 400;
                        color: #8B91A2;
                    }
                }
            }

        }
    }
}
</style>