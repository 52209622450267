<template>
    <div class="gsc-filters-wrapper">
        <div class="header">
            <h2 class="title">Filters</h2>
            <div class="saved-filters-wrapper">
                <custom-v-select
                    id="filters"
                    options-label="filter_name"
                    options-value="id"
                    select-type="id"
                    search-key="filter_name"
                    data-cy="filters-dropdown"
                    :placeholder="$t('select-saved-filters')"
                    search
                    :options="getSavedFilterList"
                    :value.sync="currentFilter"
                    dropdown-menu-data-cy="filters-list"
                    dropdown-search-data-cy="filters-search"
                    @change="setSavedFilter"
                />
            </div>
        </div>
        <div class="filters-body">
            <div v-for="(filter, key) in filterValues" :key="filter.label" class="form-row">
                <p class="filter-label">
                    {{ filter.label }}
                    <span
                        v-if="filter.tooltip"
                        @mouseover="tooltip({ text: filter.tooltip, event: $event })"
                        class="kw kw-info"
                    />
                </p>

                <kw-input-field
                    v-if="filter.type==='text'"
                    v-model.trim="filterValues[key].value"
                    :name="filter.label"
                />

                <div v-else-if="filter.type==='numeric'" class="select-row">
                    <v-select
                        :options="filterBy"
                        placeholder="Greater than"
                        :value.sync="filterValues[key].filter"
                        @change="v=>setFilter(key, v)"
                    />
                    <kw-input-field
                        v-model.trim="filterValues[key].value"
                        :name="key"
                        isNumeric
                        allowDecimals
                    />
                    <kw-input-field
                        v-if="filterValues[key].filter==='between'"
                        v-model.trim="filterValues[key].max"
                        :name="`${key}Max`"
                        isNumeric
                        allowDecimals
                    />
                </div>

                <div v-else-if="filter.type==='dropdown'" class="form-row full-width">
                    <v-select
                        :options="filter.options"
                        :placeholder="filter.placeholder"
                        :value.sync="filterValues[key].value"
                        @change="v => onFilterChange(key, v)"
                    />
                </div>
            </div>
        </div>
        <div class="footer">
            <kw-button
                button-type="text"
                button-text="Save filter"
                prepend-icon-class="kw kw-plus"
                @click="onSave"
            />
            <div class="button-group">
                <kw-button
                    button-type="secondary"
                    :button-text="$t('close')"
                    class="view-removed-list"
                    @click="close"
                />
                <kw-button
                    @click="onApply"
                    button-text="Apply Filters"
                    class="view-removed-list"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import { filterFields, filterBy } from './filterFields'
const vSelect = () => import('vue-strap/src/Select');

export default {
    name: 'KeywordSuggestionsFilters',
    mixins: [ToolTipRenderMixin],
    components: {
        'v-select': vSelect,
    },
    props: {
        tab: {
            type: String,
            default: 'suggestions'
        },
    },
    data() {
        return {
            currentFilter: null,
            filterValues: {},
            filterBy
        };
    },
    computed: {
        ...mapGetters([
            'getGSCFilters',
            'getCompetitorKWfilters',
            'getRelatedKWfilters',
            'getSavedFilterList',
            'getCurrentSavedFilter'
        ])
    },
    mounted() {
        this.fetchGSCFilters({ tab_name: this.tab });
        this.filterValues = _.cloneDeep(filterFields[this.tab]);
        let filtersTmp;
        if (this.tab === 'suggestions') {
            filtersTmp = this.getGSCFilters;
        } else if (this.tab === 'related') {
            filtersTmp = this.getRelatedKWfilters;
        } else if (this.tab === 'competitors') {
            filtersTmp = this.getCompetitorKWfilters;
        }
        if (filtersTmp) {
            Object.entries(filtersTmp).forEach(([key, val]) => {
                this.filterValues[key].value = val.value;
                if (val.filter) {
                    this.filterValues[key].filter = val.filter;
                }
                if (val.max) {
                    this.filterValues[key].max = val.max;
                }
            })
        }
        if (this.getCurrentSavedFilter[this.tab]) {
            this.currentFilter = this.getCurrentSavedFilter[this.tab].id;
        }
    },
    methods: {
        ...mapActions([
            'setGSCFilters',
            'fetchGSCFilters',
            'setCompetitorKWFilters',
            'setRelatedKWFilters',
            'setCurrentSavedFilter'
        ]),
        setFilter(type, value) {
            if (this.filterValues[type]) {
                this.filterValues[type].filter = value;
                if (this.filterValues[type].max && value !== 'between') {
                    this.filterValues[type].max = '';
                }
            }
        },
        onFilterChange(key, value) {
            this.filterValues[key].value = value;
        },
        close() {
            this.$emit('close');
        },
        onSave() {
            this.$emit('onSave', this.filterValues);
        },
        onApply() {
            if (this.tab === 'suggestions') {
                this.setGSCFilters(this.filterValues);
            } else if (this.tab === 'related') {
                this.setRelatedKWFilters(this.filterValues);
            } else if (this.tab === 'competitors') {
                this.setCompetitorKWFilters(this.filterValues);
            }
            this.$emit('onApply', this.filterValues);
            const filterItem = this.getSavedFilterList.find(item => item.id === this.currentFilter);
            this.setCurrentSavedFilter({ key: this.tab, value: filterItem });
            this.close();
        },
        setSavedFilter(id) {
            this.currentFilter = id;
            const filterItem = this.getSavedFilterList.find(item => item.id === this.currentFilter);
            this.filterValues = _.cloneDeep(filterFields[this.tab]);

            Object.keys(this.filterValues).forEach((key) => {
                if (filterItem?.filters) {
                    if (filterItem.filters[key]) {
                        this.filterValues[key].value = filterItem.filters[key];
                    }
                    if (filterItem.filters[`${key}_symbol`]) {
                        this.filterValues[key].filter = filterItem.filters[`${key}_symbol`];
                    }
                    if (filterItem.filters[`${key}_min`]) {
                        this.filterValues[key].value = filterItem.filters[`${key}_min`].toString();
                    }
                    if (filterItem.filters[`${key}_max`]) {
                        this.filterValues[key].max = filterItem.filters[`${key}_max`].toString();
                    }
                }
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.gsc-filters-wrapper {
    width: 100%;
    font-family: Roboto;

    .header {
        padding: 18px 18px 18px;

        h2.title {
            font-size: 24px;
            font-weight: 500;
            line-height: 20px;
            margin: 0;
        }
    }

    .saved-filters-wrapper {
        margin-top: 11px;

        /deep/ .dropdown-list {
            width: 100%;
            position: relative;
        }

        /deep/ .btn-group {
            width: 100%;

            .form-control.dropdown-toggle {
                border-color: kw-color(kw-white, 5) !important;
            }
        }
    }

    .filters-body {
        padding: 18px 18px 8px;
        border-top: 1px solid kw-color(kw-light-grey, 1);
        border-bottom: 1px solid kw-color(kw-light-grey, 1);

        .form-row {
            width: 100%;
            margin-bottom: 11px;

            &.full-width {
                /deep/ .btn-group {
                    width: 100%;
                }
            }
        }

        .filter-label {
            font-size: 13px;
            font-weight: 500;
            line-height: 14px;
            margin-bottom: 8px;

            .kw-info {
                margin-left: 4px;
                font-weight: 700;
                font-size: 12px;
                color: kw-color(kw-light-grey, 5);
            }
        }

        .select-row {
            display: flex;
            gap: 8px;

            &>* {
                width: 100%;
                max-width: 147px;
            }
        }
    }

    .footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 18px 8px;

        .button-group {
            display: flex;
            gap: 8px;

            .kw-button {
                height: 40px;
            }
        }
    }

    /deep/ .kw__inputfield__name .kw__input,
    /deep/ .btn-group .form-control.dropdown-toggle {
        border-color: kw-color(kw-white, 5) !important;
    }
}
</style>
