import client from '../client';
import interceptors from '../interceptors';
const gscInterceptor = interceptors.get('gsc');

client.interceptors.response.use(
    gscInterceptor.s,
    gscInterceptor.e,
);

const resource = '/google-search-console';
const googleAdsResource = '/ideas';

const encodedId = id => encodeURIComponent(id);

export default {
    fetchAccount: async () => await client.get(`${resource}/account`),
    fetchProfiles: async () => await client.get(`${resource}/profiles`),
    fetchProfileConnection: async (payload) => await client.get(`${resource}/connection`, payload),
    fetchProfileConnections: async () => await client.get(`${resource}/connections`),
    createProfileConnections: async (payload) => await client.post(`${resource}/connections`, payload),
    updateProfileConnection: async (connectionId, payload) => await client.put(`${resource}/connections/${encodedId(connectionId)}`, payload),
    deleteProfileConnection: async (connectionId) => await client.delete(`${resource}/connections/${encodedId(connectionId)}`),

    fetchSuggestions: async (connectionId, payload) => await client.get(`${resource}/suggested-keywords/${encodedId(connectionId)}`, payload),

    fetchDeletedSuggestions: async (connectionId) => await client.get(`${resource}/${encodedId(connectionId)}/deleted-keywords`),
    deleteSuggestions: async (connectionId, payload) => await client.post(`${resource}/${encodedId(connectionId)}/deleted-keywords`, payload),
    restoreSuggestions: async (connectionId, payload) => await client.put(`${resource}/${encodedId(connectionId)}/deleted-keywords`, payload),

    createKeywordsFromSuggestions: async (connectionId, payload) => await client.put(`${resource}/${encodedId(connectionId)}/create-keywords`, payload),
    fetchRelatedKeywords: async (payload) => await client.get(`${googleAdsResource}/related`, payload),
    fetchCompetitorKeywords: async (payload) => await client.get(`${googleAdsResource}/competitor`, payload),

    fetchFilters: async (projectId, tabName) => await client.get(`/project/${projectId}/filters/${tabName}`),
    createFilter: async (projectId, payload) => await client.post(`/project/${projectId}/filter`, payload),
    deleteFilter: async (projectId, filterId) => await client.delete(`/project/${projectId}/filter/${filterId}`),
};
