import i18n from '@/i18n';

const suggestionsFields = [
    { class: 'gsc-kw', name: 'kw', partOfTable: 50, title: i18n.t('gsc-suggested-kw'), tooltip: i18n.t('gsc-suggested-kw-tooltip'), sorter: 'kw' },
    { class: 'gsc-position', name: 'position', partOfTable: 12, title: i18n.t('gsc-position'), tooltip: i18n.t('avg-position-tooltip'), sorter: 'position' },
    { class: 'gsc-impressions', name: 'impressions', partOfTable: 12, title: i18n.t('gsc-impressions'), tooltip: i18n.t('impressions-tooltip'), sorter: 'impressions' },
    { class: 'gsc-clicks', name: 'clicks', partOfTable: 12, title: i18n.t('gsc-clicks'), tooltip: i18n.t('gsc-click-tootlip'), sorter: 'clicks' },
    { class: 'gsc-ctr', name: 'ctr', partOfTable: 12, title: i18n.t('gsc-ctr'), tooltip: i18n.t('ctr-tooltip'), sorter: 'ctr' },
];

const relatedKwsFields = [
    { class: 'gsc-kw', name: 'keyword', partOfTable: 3, title: i18n.t('related-keywords'), sorter: 'keyword' },
    { class: 'gsc-ms', name: 'ms', partOfTable: 1, title: i18n.t('search-volume'), tooltip: i18n.t('gsc-search-volume-tooltip'), sorter: 'ms' },
    { class: 'gsc-competition', name: 'competition', partOfTable: 2, title: i18n.t('competition'), tooltip: i18n.t('gsc-competition-tooltip'), sorter: 'competition' },
    { class: 'gsc-cpc', name: 'cpc', partOfTable: 1, title: 'CPC', tooltip: i18n.t('gsc-cpc-tooltip'), sorter: 'cpc' },
];

export { suggestionsFields, relatedKwsFields };