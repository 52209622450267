<template>
    <div class="connect-gsc">
        <p>{{$t('connect-your-google-search-console-account')}}</p>
        <kw-button
            @click="$emit('onConnect')"
            :button-text="$t('connect-gsc-account')"
        />
    </div>
</template>

<script>
    export default {
        name: "ConnectGSCAccount",
    }
</script>

<style lang="scss" scoped>
    .connect-gsc {
        width: 100%;
        position: relative;
        p {
            max-width: 600px;
            font-size: 14px;
            line-height: 16px;
            margin: 24px 0;
        }
            
        .kw-button {
            height: 40px;
        }
    }
</style>