<template>
    <div>
        <search-wrap :key="getSearchKey" placeholder="Enter URL" @submit="onSearch" :loading="loading" />
        <div class="filter-main">
            <current-filters
                @onSave="openSaveFilters"
                @onReset="onFiltersReset"
                v-if="getCompetitorKWfilters"
            >
                <filter-item
                    v-for="item in currentFilters"
                    :key="item.label"
                    :label="item.label"
                    :filterType="item.filterType"
                    :value="item.value"
                    @onDelete="onDeleteFilter(item.key)"
                />
            </current-filters>
            <div class="filter-row">
                <div class="filter-gsc__wrapper dropdown_container" data-cy="filter-gsc-wrap">
                    <kw-button button-type="secondary" prepend-icon-class="kw kw-filter"
                        data-cy="dashboard-filter-button" @click="toggleFilters" />
                        
                    <custom-dropdown-body ref="filtersDropdown" adjustY>
                        <div class="v-context" data-cy="dashboard-filter-menu">
                            <keyword-suggestions-filters
                                key="competitors"
                                tab="competitors"
                                @onSave="openSaveFilters"
                                @close="toggleFilters"
                                @onApply="pageChanged(1)"
                            />
                        </div>
                    </custom-dropdown-body>
                </div>
            </div>
        </div>
        <kw-button
            v-if="selectedKeywords.length"
            prepend-icon-class="kw kw-plus bold-icon"
            :loading="false"
            button-type="text"
            :button-text="$t('add-selected-to-project')"
            @click="openCreateModal"
        />
        <related-keywords-table
            :loading="loading"
            :list="getPaginatedData.currentItems"
            @onSelect="onSelect"
            :selected="selectedKeywords"
            @selectAll="selectAll"
            @sortChanged="competitorsSorterChanged"
            :sortField="getCompetitorsSort"
            @addOne="addOneKeyword"
            :currentPage="currentPage"
            :totalPages="getPaginatedData.totalPages"
            :totalCount="getPaginatedData.totalItems"
            @pageChanged="pageChanged"
        />
        <empty-table v-if="!getGSCCompetitorsData.length && !loading">{{ $t('competitors-empty-table') }}</empty-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RelatedKeywordsTable from './RelatedKeywordsTable.vue';
import SearchWrap from './SearchWrap.vue';
import EmptyTable from './EmptyTable.vue';
import AddKeywordsByGSCModal from '../modals/AddKeywordsByGSCModal.vue';
import KeywordSuggestionsFilters from './KeywordSuggestionsFilters.vue'
import CurrentFilters from './CurrentFilters.vue';
import FilterItem from './FilterItem.vue';
import SaveFilterModal from '@/components/modals/SaveFilterModal.vue';
import { paginateData } from '@/helpers';

export default {
    name: "RelatedKeywords",
    components: {
        'related-keywords-table': RelatedKeywordsTable,
        'search-wrap': SearchWrap,
        'empty-table': EmptyTable,
        'keyword-suggestions-filters': KeywordSuggestionsFilters,
        'current-filters': CurrentFilters,
        'filter-item': FilterItem
    },
    data() {
        return {
            selectedKeywords: [],
            loading: false,
            currentPage: 1
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getCompetitorsSort',
            'getGSCCompetitorsData',
            'getCompetitorKWfilters'
        ]),
        getSearchKey() {
            return `${this.getCurrentProject?.id}_search_comp`;
        },
        currentFilters() {
            const result = [];
            if (this.getCompetitorKWfilters) {
                Object.entries(this.getCompetitorKWfilters).forEach(([key, fValue]) => {
                    let currentValue = fValue.value;

                    if (fValue.filter === 'between') {
                        currentValue = [fValue.value, fValue.max];
                    }
                    result.push({ 
                        key,
                        label: this.$t(`kw-int.filters.${key}`),
                        filterType: fValue.filter,
                        value: currentValue
                    });
                });
            }
            return result;
        },
        getPaginatedData() {
            return paginateData(this.getGSCCompetitorsData, this.currentPage, 100);
        }
    },
    methods: {
        ...mapActions([
            'fetchCompetitorKeywords',
            'setCompetitorsKeywords',
            'competitorsSorterChanged',
            'setCompetitorKWFilters',
            'setCurrentSavedFilter',
            'deleteCompetitorKWFilterItem'
        ]),
        onSearch(data) {
            this.loading = true;
            this.fetchCompetitorKeywords({
                url: data.search,
                language: data.lang,
                country: data.country,
                region: data.region,
                city: data.city,
            }).finally(() => {
                this.loading = false;
            })
        },
        addOneKeyword(keyword) {
            this.selectedKeywords = [keyword];
            this.openCreateModal();
        },
        openCreateModal() {
            this.$modal.show(
                AddKeywordsByGSCModal,
                {
                    keywords: this.selectedKeywords,
                    activeTag: this.getActiveTag,
                    connection: this.getGSCProfileCurrentConnection || {},
                },
                {
                    classes: 'v--modal',
                    height: 'auto',
                    name: 'AddKeywordsByGSCModal',
                    width: 512,
                },
                {
                    'before-close': this.clearSelection,
                },
            );
        },
        clearSelection() {
            this.selectedKeywords = [];
        },
        onSelect(keyword, isSelected) {
            if (isSelected) {
                this.selectedKeywords = this.selectedKeywords.filter(
                    item => item !== keyword,
                );
            } else {
                this.selectedKeywords.push(keyword);
            }
        },
        resetData() {
            this.setCompetitorsKeywords([]);
            this.clearSelection();
            this.currentPage = 1;
        },
        selectAll(allSelected) {
            this.selectedKeywords = allSelected
                ? []
                : this.getGSCCompetitorsData.map(a => a['keyword']);
        },
        toggleFilters() {
            this.$refs.filtersDropdown.toggleDropdownOpen();
        },
        closeFiltersDropdown() {
            if (this.$refs.filtersDropdown?.getIsDropdownOpened) {
                this.$refs.filtersDropdown.closeDropdown();
            }
        },
        openSaveFilters(filters) {
            if (!this.getCurrentProject) return;
            const currentFilters = filters || this.getCompetitorKWfilters
            this.$modal.show(
                SaveFilterModal,
                {
                    project: this.getCurrentProject,
                    filters: currentFilters,
                    tabName: 'competitors'
                },
                {
                    width: 408,
                    height: 'auto',
                    classes: 'v--modal center-modal-popup',
                },
                {
                    'before-close': this.closeFiltersDropdown,
                },
            );
        },
        onFiltersReset() {
            this.setCurrentSavedFilter({ key: 'competitors', value: null });
            this.setCompetitorKWFilters();
        },
        onDeleteFilter(filterKey) {
            this.deleteCompetitorKWFilterItem(filterKey);
        },
        pageChanged(page) {
            this.currentPage = page;
        }
    },
    watch: {
        '$route'(to, from) {
            if (to !== from) {
                this.resetData();
            }
        }
    },
}
</script>
