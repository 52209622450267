<template>
    <div class="display-settings__table" data-cy="project-table-settings">
        <!-- <span class="table_icon table_setting_icon" /> -->
        <h2
            class="settings__subtitle"
            v-text="$t('ranking-table-settings')"
        />
        <settings-checkbox
            settingName="subcat"
            :label="$t('groups-setting')"
            :isChecked="checkboxCondition('subcat')"
            :settingTooltip="$t('groups-setting-tooltip')"
            @toggleCheckbox="toggleCheckbox"
        />
        <settings-checkbox
            settingName="kwtotals"
            :label="$t('display-keywords-totals')"
            :isChecked="checkboxCondition('kwtotals')"
            :settingTooltip="$t('display-keywords-totals-tooltip')"
            @toggleCheckbox="toggleCheckbox"
        />
        <div class="table-setting-wrapper">
            <label class="settings__label">
                {{ $t('global-search-volume') }}
                <span
                    class="kw kw-info"
                    @mouseover="tooltip({ text: $t('global-search-volume-tooltip'), event: $event })"
                />
            </label>
        
            <radio-buttons
                :options="globalMSSettings"
                :value="getOtherSetting('global')"
                @radio_click="e => saveTableSettings({ setting: 'global', value: e })"
            />
        </div>
        <settings-checkbox
            settingName="rankingAreaChart"
            :label="getDisplayChartInKeywordTabText"
            :isChecked="getOtherSetting('rankingAreaChart') === 'show'"
            :settingTooltip="$t('display-visibility-and-est-tooltip')"
            @toggleCheckbox="parseRadioToCheckbox"
        />
        <settings-checkbox
            settingName="googleAnalyticsCharts"
            :label="$t('display-google-analytics-chart')"
            :isChecked="getOtherSetting('googleAnalyticsCharts') === 'show'"
            :settingTooltip="$t('google-analytics-chart-tooltip')"
            @toggleCheckbox="parseRadioToCheckbox"
        />
        <!-- table sort setting -->
        <label
            for="table-sort"
            class="settings__label"
            v-text="$t('sort-table-by')"
        />
        <div id="table-sort" class="display-settings__table-sort">
            <v-select
                options-label="label"
                options-value="value"
                :options="sortByConfig"
                :value="getDisplaySetting('sort_by')"
                @change="e => save({ setting: 'sort_by', value: e })"
            />
            <v-select
                options-label="label"
                options-value="value"
                :options="sortByOrderConfig"
                :value="getDisplaySetting('sort_by_order')"
                @change="e => save({ setting: 'sort_by_order', value: e })"
            />
        </div>
        <!-- date format setting -->
        <label for="date-format" class="settings__label">
            <span v-text="$t('date-format')" />
            <span
                class="kw kw-info"
                @mouseover="
                    tooltip({
                        text: $t('date-format-tooltip'),
                        event: $event,
                        maxWidth: 247
                    })
                "
            />
        </label>
        <v-select
            id="date-format"
            options-label="label"
            options-value="value"
            :options="dateFormatConfig"
            :value="getDisplaySetting('dateformat')"
            @change="e => save({ setting: 'dateformat', value: e })"
        />
        <!-- keywords per page setting -->
        <label
            for="keywords-per-page"
            class="settings__label"
        >
            {{ $t('amount-keywords-per-page') }}
            <span
                class="kw kw-info"
                @mouseover="
                    tooltip({
                        text: $t('amount-keywords-per-page-tooltip'),
                        event: $event,
                        maxWidth: 247
                    })
                "
            />
        </label>
        <v-select
            id="keywords-per-page"
            ref="perPageSelector"
            options-label="label"
            options-value="value"
            :options="perPageConfig"
            :value="getOtherSetting('display_length')"
            data-cy="display-keywords-per-page"
            @change="setPerPage"
        />
        <settings-checkbox
            settingName="gaps"
            :label="$t('rank-drop-setting')"
            :isChecked="checkboxCondition('gaps')"
            :settingTooltip="$t('rank-drop-setting-tooltip')"
            @toggleCheckbox="toggleCheckbox"
        />
        <settings-checkbox
            settingName="googleIncidentsChart"
            :label="$t('show-google-incidents')"
            :isChecked="getOtherSetting('googleIncidentsChart') === 'show'"
            :settingTooltip="$t('show-google-incidents-tooltip')"
            @toggleCheckbox="parseRadioToCheckbox"
        />
        <label
            for="chart-height"
            class="settings__label settings__label_chart-height"
        >
            <span>
                {{ $t('chart-height') }}
                <span
                    class="kw kw-info"
                    @mouseover="
                        tooltip({
                            text: $t('chart-height-tooltip'),
                            event: $event,
                            maxWidth: 247
                        })
                    "
                />
            </span>
            <span v-text="`${getOtherSetting('chartheight')}px`" />
        </label>
        <vue-slider
            id="chart-height"
            :dot-size="16"
            :height="4"
            :interval="25"
            :lazy="true"
            :max="500"
            :min="150"
            :value="getOtherSetting('chartheight')"
            @change="
                e =>
                    updateUserTableSettings({
                        setting: 'chartheight',
                        value: e,
                    })
            "
        />
        <div class="display-settings__limits">
            <span v-text="'150'" />
            <span v-text="'500'" />
        </div>
    </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css';
import { mapGetters, mapActions } from 'vuex';
import { MOBILE_WIDTH_RESOLUTION_PHONE } from '@/constants';
import SimpleConfirmWithCustomHandler from 'components/modals/SimpleConfirmWithCustomHandler';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import vSelect from 'vue-strap/src/Select';
import VueSlider from 'vue-slider-component';
import {
    dateFormatConfig,
    perPageConfig,
    sortByConfig,
    sortByOrderConfig,
    globalMSSettings
} from '@/components/settings/configs';
import SettingsCheckbox from './SettingsCheckbox.vue';
import { oldOverviewUsers } from "@/constants";

export default {
    name: 'ProjectTableSettings',
    components: {
        'v-select': vSelect,
        'vue-slider': VueSlider,
        'settings-checkbox': SettingsCheckbox
    },
    mixins: [ValidationMixin, ToolTipRenderMixin],
    data() {
        return {
            dateFormatConfig,
            err: { metrics_range: {} },
            perPageConfig,
            sortByConfig,
            sortByOrderConfig,
            inited: false,
            globalMSSettings,
        };
    },
    computed: {
        ...mapGetters([
            'getDisplaySetting',
            'getOtherSetting',
            'getUserDataProp'
        ]),
        getDisplayChartInKeywordTabText() {
            const email = this.getUserDataProp("email");
            return oldOverviewUsers.includes(email)
                ? this.$t("ranking-area-chart")
                : this.$t("display-visibility-and-est-traffic");
        }
    },
    mounted() {
        this.metrics_range = this.getDisplaySetting('metrics_range').map(el =>
            el.toString(),
        );
        this.$nextTick(() => (this.inited = true));
    },
    methods: {
        ...mapActions([
            'updateUserDisplaySettings',
            'updateUserTableSettings',
        ]),
        save(setting) {
            if (!this.inited) return;
            this.updateUserDisplaySettings(setting);
        },
        saveTableSettings(setting) {
            if (!this.inited) return;
            this.updateUserTableSettings(setting);
        },
        setRange() {
            this.err.metrics_range = this.metricsRangeValidator(
                this.metrics_range,
            );

            if (!_.isEmpty(this.err.metrics_range)) {
                this.$toastr.e(this.err.metrics_range.text);
                if (this.err.metrics_range.type !== 'duplicates-error') {
                    this.err.metrics_range.indexes.forEach(
                        i => (this.metrics_range[i] = ''),
                    );
                }

                return;
            }

            this.save({ setting: 'metrics_range', value: this.metrics_range });
        },
        setPerPage(newValue) {
            const oldValue = this.getOtherSetting('display_length');

            if (!this.inited || newValue === oldValue) return;

            if (newValue <= 100) {
                this.updateUserTableSettings({
                    setting: 'display_length',
                    value: newValue,
                });
                return;
            }

            // if per page more than 100 kw we should as confirmation from user
            this.$modal.show(
                SimpleConfirmWithCustomHandler,
                {
                    handler: confirmed => {
                        confirmed
                            ? this.updateUserTableSettings({
                                  setting: 'display_length',
                                  value: newValue,
                              })
                            : (this.$refs.perPageSelector.val = oldValue);
                    },
                    header: this.$t('warning-100-rows-exceed'),
                    positiveButton: this.$t('ok'),
                },
                {
                    classes: 'v--modal center-modal-popup',
                    clickToClose:
                        window.screen.width > MOBILE_WIDTH_RESOLUTION_PHONE,
                    height: 'auto',
                    width: 282,
                },
            );
        },
        rangeInputError(i) {
            const { indexes } = this.err.metrics_range;
            return {
                error: indexes && indexes.includes(i),
            };
        },
        checkboxCondition(name) {
            return this.getOtherSetting(name) === "show";
        },
        toggleCheckbox(name) {
            this.updateUserTableSettings({
                setting: name,
                value: this.getOtherSetting(name) === "show" ? "hide" : "show"
            });
        },
        parseRadioToCheckbox(value) {
            const isShown = this.getOtherSetting(value)  ===  "show" ? "hide" : "show";
            this.saveTableSettings({ setting: value, value: isShown });
        }
    },
};
</script>

<style lang="scss">
@import '~sass/partials/dropdowns';

.display-settings__table {
    display: flex;
    flex-flow: column nowrap;
    padding: 43px 24px 22px;

    .settings__subtitle {
        margin-bottom: 32px;
    }

    .display-settings__item {
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid kw-color(kw-light-grey, 10);
    }
    .btn-group {
        .form-control.dropdown-toggle {
            border-color: kw-color(kw-white, 5)!important;
            height: 32px;
            font-size: 13px;
        }
    }
    .button-closer {
        align-self: flex-end;
    }

    .form-control.dropdown-toggle {
        @extend .custom-dropdown;
        color: kw-color(kw-black, 1);
    }
    label.settings__label {
        font-size: 13px;
    }
    .table-setting-wrapper {
        border-bottom: 1px solid kw-color(kw-light-grey, 10);
        margin-bottom: 16px;
        .radio-buttons {
            padding-bottom: 4px;
        }
    }
    .btn-group {
        margin-bottom: 16px;

        .btn {
            border-color: #dee5ec;
            box-shadow: none;
            color: kw-color(kw-black, 1);
            font-size: 13px;
            line-height: 15px;
            outline: transparent !important;
            width: 50%;

            &:hover {
                background-color: transparent;
            }

            &.active {
                background-color: kw-color(kw-blue, 1);
                border-color: kw-color(kw-blue, 1);
                color: kw-color(kw-white, 1);
            }
        }
    }

    .open .form-control.dropdown-toggle {
        @extend .custom-dropdown_active;
    }

    .dropdown-menu {
        @extend .custom-scroll;
        @extend .custom-dropdown-menu;
        width: 100%;

        .glyphicon.glyphicon-ok.check-mark:before {
            content: '';
        }
    }

    .vue-slider {
        padding: 10px 0 8px 0 !important;

        .vue-slider-rail {
            background-color: #dee5ec;
            box-shadow: none;

            .vue-slider-process {
                box-shadow: none;
                background-color: kw-color(kw-blue, 1);
            }

            .vue-slider-dot-handle {
                background-color: kw-color(kw-blue, 1);
                border: none;
            }
        }
    }

    .display-settings__subitem {
        align-items: center;
        display: flex;
        flex-grow: 1;
        padding: 5px 0 5px 24px;
        position: relative;

        &:before {
            border-bottom: 1px solid #c4c4c4;
            content: '';
            left: 8px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 11px;
        }

        &:last-child {
            &:after {
                background-color: kw-color(kw-white, 1);
                content: '';
                height: 28px;
                left: 6px;
                position: absolute;
                top: calc(50% + 1px);
                width: 4px;
            }
        }

        @media screen and (max-width: 800px) {
            flex-basis: 100%;
        }
    }

    .settings__label_other {
        cursor: pointer;
        flex-grow: 1;
        font-weight: normal !important;
        margin-bottom: 0;
        padding-left: 8px;
    }

    .settings__label_other {
        justify-content: flex-start;
        text-transform: unset;

        span {
            color: kw-color(kw-green, 1);
        }

        @media screen and (max-width: 480px) {
            max-width: calc(100% - 16px);
        }
    }
}

#table-width {
    margin-bottom: 8px;
}

.display-settings__limits {
    color: kw-color(kw-grey, 1);
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    line-height: 15px;
    padding-bottom: 20px;
}

.display-settings__ranges {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    input {
        text-align: center;
        width: 54px !important;

        @media screen and (max-width: 480px) {
            padding: 10px 10px;
            width: 44px;
        }
    }
}

.display-settings__table-sort {
    display: flex;
    flex-flow: row nowrap;

    .btn-group {
        width: 50%;

        &:first-child {
            margin-right: 8px;
        }
    }
}

.settings__label {
    justify-content: initial !important;

    .kw-info {
        color: kw-color(kw-light-grey, 5);
        font-size: 12px;
        font-weight: 700;
        margin-left: 6px;
    }
}

.settings__label_chart-height {
    justify-content: space-between !important;
}

.kw-information {
    color: #828f9c;
    font-size: 12px;
    font-weight: 700;
    margin: 0 4px;
}
</style>
